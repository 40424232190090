import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../css/TaskCard.css"



function TaskCard({ Task }) {

    let navigate = useNavigate()

    const changeLink = (event) => {
        //console.log(Task._id)
        let idString = Task._id.toString()
        navigate("/Profile/"+idString);
    }

    //convert Date

    let d = new Date(Task.lastUpdated)


    let updatedDate = (d.getMonth() + 1).toString() + "/" + d.getDate().toString() + "/" + d.getFullYear().toString()

    //console.log(updatedDate)


    return (
        <>

            <Box id="TaskCard" sx={{ margin: 3, marginTop: 1, marginBottom: 1, cursor: "pointer" }} onClick={changeLink} href="/Task">
                <Typography variant="h4" sx={{ fontFamily: "'Allerta Stencil', sans-serif;", padding: 3 }}>{Task.taskName}</Typography>
                <Typography variant="h5" sx={{ paddingBottom: 5 }}> Last Updated Date: {updatedDate}</Typography>
                <Typography variant="h5"> Tracking Variables:<br></br> {Task.trackingVariableName}: {Task.trackingVariableValue}</Typography>
            </Box>

        </>
    )
}

export default TaskCard