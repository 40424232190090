import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()


    const login = async (email, password) => {

        setIsLoading(true)
        setError(null)


        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/user/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        })

        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }

        if (response.ok) {

            //var delayInMilliseconds = 30000; // Simulate Cold Start of Backend Server

           // setTimeout(function () {

                //save the user token to local storage
                localStorage.setItem('user', JSON.stringify(json))

                //update the auth context
                dispatch({ type: 'LOGIN', payload: json })

                setIsLoading(false)


           // }, delayInMilliseconds);


        }
    }

    return { login, isLoading, error }
}