import { Typography, Box, Fade } from "@mui/material";
import { useState } from 'react'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useSignup } from "../hooks/useSignup";


const CreateAccount = () => {

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);


    //import use signuphook
    const { signup, error, isLoading } = useSignup()


    const handleSubmit = async (e) => {
        e.preventDefault()

        await signup(email.toLowerCase(), password, firstname, lastname, username, confirmPassword)
    }


    return (
        <>
            <Box

                id="test"
                sx={{
                    marginTop: "5%",
                    marginLeft: { xs: '5%', sm: '15%', md: '25%', lg: '35%' },
                    marginRight: { xs: '5%', sm: '15%', md: '25%', lg: '35%' },
                    marginBottom: '10%'
                }}
            >
                <Typography variant="h4" sx={{
                    paddingTop: 3,
                    paddingBottom: 6
                }}> Create Account</Typography>

                <TextField id="outlined-basic-firstname" label="First Name" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: .5,
                    marginBottom: .5

                }}
                    value={firstname}
                    onChange={(e) => {
                        setFirstname(e.target.value);
                    }}
                />
                <TextField id="outlined-basic-lastname" label="last Name" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: .5,
                    marginBottom: .5

                }}
                    value={lastname}
                    onChange={(e) => {
                        setLastname(e.target.value);
                    }}
                />
                <TextField id="outlined-basic-email" label="Email Address" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: .5,
                    marginBottom: .5

                }}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    inputProps={{ style: { textTransform: "lowercase" } }}
                />
                <TextField id="outlined-basic-username" label="User Name" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: .5,
                    marginBottom: .5

                }}
                    value={username}
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                />
                <TextField id="outlined-basic-password" label="Password" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: .5,
                    marginBottom: .5

                }}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
                <TextField id="outlined-basic-cpassword" label="Confirm Password" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: .5,
                    marginBottom: .5
                }}
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                    }}
                />

                <FormControlLabel
                    value="end"
                    control={<Checkbox onClick={handleClickShowPassword} />}
                    label="Show Passwords"
                    labelPlacement="end"
                    sx={{
                        marginLeft: { xs: 4, sm: 10 },
                        marginRight: { xs: 4, sm: 10 },
                        marginTop: 0,
                        marginBottom: 1
                    }}
                />

                <Typography sx={{ color: 'red', marginRight: '10%', marginLeft: '10%' }}>{error}</Typography>

                <Box sx={{

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 3,
                    marginTop: 2


                }}>
                    <Button variant="outlined" size="small" sx={{
                        borderRadius: 25,
                        border: 3,
                        ":hover": {
                            border: 3,
                        },
                        borderColor: "primary.dark",
                        color: "primary.light",
                        fontFamily: 'Allerta Stencil, sans-serif',
                        padding: 1,
                        marginLeft: isLoading ? '30px' : '0px'
                    }} ><Link to='/Login'>Have An Account</Link></Button>

                    <Button variant="contained" size="large" sx={{
                        borderRadius: 25,
                        backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                        fontFamily: 'Allerta Stencil, sans-serif',
                        marginLeft: { xs: 5, sm: 17 },
                    }}
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >
                        Create
                    </Button>



                    {isLoading && (
                        <CircularProgress
                            size={28}
                            sx={{
                                color: '#000000',
                                position: 'relative',
                                //top: { xs: '18%', sm: '18%' },
                                left: { xs: '-65px', sm: '-65px' },
                            }}
                        />
                    )}
                </Box>

                <Fade
                    in={isLoading}
                    style={{
                        transitionDelay: isLoading ? '10000ms' : '0ms'
                    }}

                    unmountOnExit
                >


                    <Typography sx={{
                        marginRight: '20%',
                        marginLeft: '20%',
                        marginBottom: '20px',
                        color: '#880808'
                    }}>
                        Waking up Server Please Wait . . .</Typography>

                </Fade>



            </Box>
        </>
    )
}

export default CreateAccount