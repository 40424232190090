import { Link } from 'react-router-dom'
import { Box } from '@mui/material';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import '../css/Navbar.css'
import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

const Navbar = (props) => {

    const drawerWidth = 240;

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const navigate = useNavigate()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const { logout } = useLogout()
    const { user } = useAuthContext()

    const handleLogout = () => {
        logout()
        navigate('/Login')
    }

    const drawer = (
        <div>
            <Box className="DrawTitle"><Typography variant='h5' sx={{ margin: 2 }}>DIYTaskTracker</Typography></Box>

            <Divider />

            {!user && (

                <List>

                    <ListItem key={'Login'} disablePadding>

                        <ListItemButton>

                            <Link to={'/Login'}><ListItemText primary={'Login'} /></Link>
                        </ListItemButton>
                    </ListItem>

                    <ListItem key={'Create Account'} disablePadding>

                        <ListItemButton>

                            <Link to={"/CreateAccount"}><ListItemText primary={'Create Account'} /></Link>
                        </ListItemButton>
                    </ListItem>

                </List>
            )}

            {user && (

                <List>

                    <ListItem key={'Hello ' + user.name} disablePadding>

                        <ListItemButton>

                            <Link to={'/Profile'}><ListItemText primary={'Hello ' + user.name} /> </Link>

                        </ListItemButton>
                    </ListItem>

                    <ListItem key={"Logout"} disablePadding>

                        <ListItemButton onClick={handleLogout}>

                            <ListItemText primary={'Logout'} />
                        </ListItemButton>
                    </ListItem>

                </List>
            )}


        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box className='header' sx={{ display: 'flex', flexDirection: { md: 'column', xl: 'row' }, alignItems: { md: 'center', xl: 'normal' }, marginTop: '30px', marginLeft: '15px', marginRight: '15px' }}>

            <Box className='start' sx={{ fontSize: { md: '50px', xl: '35px' }, marginBottom: { md: '20px', xl: '0px' } }}>
                <IconButton
                    className='Menubutton'
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ paddingBottom: "16px", display: { md: 'none' } }}
                    size='medium'
                >
                    <MenuIcon />
                </IconButton>
                <Link to="/">
                    DIYTaskTracker
                </Link>
            </Box>

            <Box className='center' sx={{ marginBottom: { md: '20px', xl: '0px' } }}>
                <div className='Searchbar'>
                    <input type="text" id="Searchbar" name='Searchbar' placeholder='Coming Soon . . .' size={30} disabled></input>
                    <SearchIcon fontSize='medium'></SearchIcon>
                </div>

            </Box>

            <Box className='end' sx={{ display: 'flex', alignItems: 'center' }}>

                {!user && (
                    <Link to='/Login'> <Typography sx={{ fontSize: { xs: '0px', md: '25px' } }}>Login</Typography></Link>
                )}

                {!user && (
                    <Button variant="contained" size="large" sx={{
                        borderRadius: 25,
                        backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                        fontFamily: 'Allerta Stencil, sans-serif',
                        marginLeft: { md: 15, xl: 2 },
                        display: { xs: 'none', md: 'inline-flex' }
                    }}> <Link to='/CreateAccount'>Create Account</Link></Button>
                )}



                {user && (
                    <Link to='/Profile'> <Typography sx={{ fontSize: { xs: '0px', md: '25px' } }}>Hi {user.name}</Typography></Link>
                )}

                {user && (
                    <Button variant="outlined" size="large" sx={{
                        borderRadius: 25,
                        border: 3,
                        ":hover": {
                            border: 3,
                        },
                        borderColor: "primary.dark",
                        color: "primary.light",
                        fontFamily: 'Allerta Stencil, sans-serif',
                        marginLeft: { xs: '35px', sm: '80px' },
                        alignItems: 'center',
                        display: { xs: 'none', md: 'flex' }
                    }}
                        onClick={handleLogout}
                    >Logout</Button>

                )}

            </Box>

            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>

        </Box >
    )
}

export default Navbar;