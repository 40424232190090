import Navbar from "../components/Navbar"
import * as React from 'react';
import { useAuthContext } from "../hooks/useAuthContext";
import { useEffect, useState } from "react"
import { useLogout } from '../hooks/useLogout';
import TaskCard from "../components/TaskCard"
import Footer from "../components/Footer"
import { Box, Modal, Typography } from "@mui/material"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from "react-router-dom";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormLabel from '@mui/material/FormLabel';
import "../css/TaskCard.css"
import "../css/CreateModal.css"




const Profile = () => {

    const [task, setTasks] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //States for form
    const [taskName, setTaskName] = useState('')
    const [trackingVariableName, setTrackingVariableName] = useState('')
    const [trackingVariableValue, setTrackingVariableValue] = useState('')
    const [trackingVariableType, setTrackingVariableType] = useState(null)
    const [lastUpdated, setLastUpdated] = useState(null)
    const [Details, setDetails] = useState('')
    const [error, setError] = useState(null)

    //Auth context
    const { user } = useAuthContext()

    const navigate = useNavigate()
    const { logout } = useLogout()

    useEffect(() => {
        const fetchTasks = async () => { // need to point to the correct endpoint
            const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/tasks', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setTasks(json)
            }

            if (response.status === 404) {
                navigate("/404Error")
            }

            if (response.status === 401) {
                console.log(json.error)

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }

        }
        if (user) {
            fetchTasks()
        }
    // Do Not Pass Login!!!!
    }, [user, navigate])


    const handleSubmit = async (e) => {

        //NEED TO CHECK IF REQUIRED INPUTS ARE EMPTY !!!!!
        if (!taskName || !trackingVariableName || !trackingVariableValue || !lastUpdated) {
            setError("Required fields have not been filled in")
            return null
        }

        if (!user) {
            setError("Musted Be Logged In")
            return null
        }

        const task = { taskName, trackingVariableName, trackingVariableValue, trackingVariableType, lastUpdated, Details }

        //Need to Change fetch location
        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/tasks', {
            method: 'POST',
            body: JSON.stringify(task),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (!response.ok) {
            setError(json.error)

            if (response.status === 401) {
                console.log(json.error)

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }
        }
        if (response.ok) {
            setError(null)
            setTaskName('')
            setTrackingVariableName('')
            setTrackingVariableValue('')
            setTrackingVariableType(null)
            setLastUpdated(null)
            setDetails('')
            handleClose()

            //change to react Link??
            navigate("/Profile/" + json._id)
        }
    }


    return (
        <>
            <Navbar></Navbar>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box id="ModalCard" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '70%', lg: '800px' },
                    
                    bgcolor: 'background.paper',
                    borderRadius: '25px',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" sx={{fontSize:{xs:'30px', lg:'35px'}}}>
                        Create Task
                    </Typography>

                    <TextField id="outlined-basic" label="Task Name" variant="outlined" size="small" sx={{
                        marginLeft: { xs: 1, lg: 15 },
                        marginRight: { xs: 1, lg: 15 },
                        marginTop: 1,
                        marginBottom: 1,
                        
                        
                    }}
                        value={taskName}
                        onChange={(e) => {
                            setTaskName(e.target.value);
                        }}

                        //InputProps={{sx:{height:{xs:45, lg:55}}}}
                        
                    />

                    <TextField id="outlined-basic" label="Tracking Variable Name" variant="outlined" size="small" sx={{
                        marginLeft: { xs: 1, lg: 15 },
                        marginRight: { xs: 1, lg: 15 },
                        marginTop: 1,
                        marginBottom: 1

                    }}
                        inputProps={{ maxLength: 10 }}
                        value={trackingVariableName}
                        onChange={(e) => {
                            setTrackingVariableName(e.target.value);
                        }}

                    />

                    <TextField id="outlined-basic" label="Tracking Variable Value" variant="outlined" size="small" sx={{
                        marginLeft: { xs: 1, lg: 15 },
                        marginRight: { xs: 1, lg: 15 },
                        marginTop: 1,
                        marginBottom: 1

                    }}
                        type="number"
                        value={trackingVariableValue}
                        onChange={(e) => {
                            setTrackingVariableValue(e.target.value);
                        }}

                    />


                    {/* <FormLabel>Tracking Type</FormLabel>
                    <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={trackingVariableType}
                        onChange={(e) => {
                            setTrackingVariableType(e.target.value)
                        }}

                        sx={{
                            flexDirection: "row",
                            justifyContent: "center"
                        }}
                    >
                        <FormControlLabel value="distance" control={<Radio />} label="Distance" />
                        <FormControlLabel value="time" control={<Radio />} label="Time" />
                    </RadioGroup> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker sx={{
                            marginLeft: { xs: 1, lg: 15 },
                            marginRight: { xs: 1, lg: 15 },
                            marginTop: 1,
                            marginBottom: 1

                        }}

                            value={lastUpdated}
                            onChange={(e) => {
                                setLastUpdated(e)
                            }}
                            slotProps={{ textField: { size: 'small' } }}
                        ></DatePicker>
                    </LocalizationProvider>

                    <TextField
                        id="outlined-multiline-static"
                        label="Details (Optional)"
                        size="small"
                        multiline
                        rows={2}
                        sx={{
                            marginLeft: { xs: 1, lg: 15 },
                            marginRight: { xs: 1, lg: 15 },
                            marginTop: 1,
                            marginBottom: 1

                        }}

                        value={Details}
                        onChange={(e) => {
                            setDetails(e.target.value)
                        }}
                    />

                    <Typography sx={{ color: 'red' }}>{error}</Typography>

                    <Box sx={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 0,
                        marginTop: 2


                    }}>
                        <Button variant="outlined" size="medium" sx={{
                            borderRadius: 25,
                            border: 3,
                            ":hover": {
                                border: 3,
                            },
                            borderColor: "primary.dark",
                            color: "primary.light",
                            fontFamily: 'Allerta Stencil, sans-serif',
                        }}
                            onClick={handleClose}
                        >Cancel</Button>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: 17,
                        }}
                            onClick={handleSubmit}
                        >Submit</Button>


                    </Box>

                </Box>

            </Modal >

            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignContent: "center", justifyContent: "space-evenly", marginTop: 5, marginLeft: "5px", marginRight: "5px" }}>
                {task && task.map((task) => (

                    <TaskCard Task={task} key={task._id}></TaskCard>
                ))}

                <Box id="TaskCardFaded" sx={{ margin: 3, marginTop: 1, marginBottom: 1, cursor: "pointer" }} onClick={handleOpen}>
                    <Typography variant="h4" sx={{ fontFamily: "'Allerta Stencil', sans-serif;", padding: 3, opacity: .8 }}>Create</Typography>
                    <Typography variant="h1" sx={{ paddingBottom: 5, opacity: .5 }}>+</Typography>

                </Box>


            </Box>

            <Footer></Footer>

        </>
    )
}

export default Profile