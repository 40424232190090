import { Button, Typography, Box } from "@mui/material";
import { Link } from 'react-router-dom'
import Navbar from "../components/Navbar";
import exampleimage from "../images/DIYTaskTrackerWatermark.png"
import ExampleCard from "../components/ExampleCard";
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="Home">
                <div className="Message">
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "black" }}>Keep Track Of</Typography>
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "primary.main" }}>What You Do</Typography>
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "secondary.main" }}>When You Do It</Typography>
                    <Button variant="contained" size="large" sx={{
                        borderRadius: 25,
                        backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                        fontFamily: 'Allerta Stencil, sans-serif',
                        marginTop: 4,
                        
                    }} > <Link to='/CreateAccount'>Create Account</Link></Button>
                    <Button variant="outlined" size="large" sx={{
                        borderRadius: 25,
                        border: 3,
                        ":hover": {
                            border: 3,
                        },
                        borderColor: "primary.dark",
                        color: "primary.light",
                        fontFamily: 'Allerta Stencil, sans-serif',
                        marginTop: 4,
                        marginLeft:{xs: '35px', sm: '80px'}
                    }} ><Link to='/Dev'>Dev Page</Link></Button>
                </div>
            </div>
            <br></br>
            <div className="Examples">

                <Typography variant="h3"sx={{fontSize: { xs: '35px', sm: '50px', md: '50px' }, textAlign:"center"}}> Example Use Cases</Typography>

            </div>

            <Box className="UseCase" sx={{ flexDirection: { xs: "column", xl: "row" } }}>

                <ExampleCard title={"Example 1"} description={"This will be a description of the image above"} image={exampleimage}></ExampleCard>

                <ExampleCard title={"Examples 2"} description={"This will be a description of the image above"} image={exampleimage}></ExampleCard>

                <ExampleCard title={"Examples 3"} description={"This will be a description of the image above"} image={exampleimage}></ExampleCard>


            </Box>

            <Footer></Footer>

        </>
    )
}

export default Home;