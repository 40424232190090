
import Navbar from "../components/Navbar";
import { useTaskContext } from "../hooks/useTaskContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { useLogout } from '../hooks/useLogout';
import * as React from 'react';

import Tracker from "../components/Tracker";
import Completed from "../components/Completed";


const Task = () => {

    const params = useParams()
    const { task, dispatch } = useTaskContext()

    //Auth context
    const { user } = useAuthContext()
    
    const navigate = useNavigate()
    const { logout } = useLogout()

    useEffect(() => {

        const idString = (params.id).toString()

        const fetchTask = async () => { // need to point to the correct endpoint
            const response = await fetch(process.env.REACT_APP_BACKENDURL+'/api/tasks/' + idString, {
                headers:{
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({ type: "SET_TASK", payload: json })
            }
            
            if(response.status === 404){
                console.log(json.error)
                navigate("/404Error")
            }

            if (response.status === 401) {
                console.log(json.error)

                if (json.error === "Token Expired") {
                    logout()
                    navigate('/Login')
                }
                else {
                    navigate("/401Error")
                }
            }
        }
        if (user) {
            fetchTask()
        }

        // Do Not Pass Login
    }, [dispatch, params.id, user, navigate])

    //prevents Task from not returing in time

    if (task != null) {
        return (

            <>
                <Navbar></Navbar>
                <Tracker></Tracker>
                <Completed></Completed>
                <Footer></Footer>
            </>
        )
    }

}

export default Task;