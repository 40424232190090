import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { TaskContextProvider } from './context/TaskContext';
import { AuthContextProvider } from './context/AuthContext';
import { ThemeProvider, createTheme } from "@mui/material"

const theme = createTheme({
  palette: {
    primary: {
      main: '#0500ff'
    },
    secondary: {
      main: '#00ff38'
    }
  },

  typography: {
    fontFamily: [
      'Allerta, sans-serif',
    ],

    h2: {
      fontSize: 70,
      fontWeight: 'bold',
      fontFamily: 'Allerta, sans-serif'
    },

    h3: {
      fontSize: 50,
      fontWeight: 'none',
      fontFamily: 'Allerta, sans-serif'
    },
    h4: {
      fontSize: 35,
      fontWeight: 'none',
      fontFamily: 'Allerta, sans-serif'
    },
    h5: {
      fontSize: 25,
      fontWeight: 'none',
      fontFamily: 'Allerta, sans-serif'
    }


  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <AuthContextProvider>
      <TaskContextProvider>
        <App />
      </TaskContextProvider>
    </AuthContextProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

