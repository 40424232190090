import { createContext, useReducer } from 'react'

//USECONTEXT
export const TaskContext = createContext()

//REDUCER
export const taskReducer = (state, action) => {
    switch (action.type) {
        case 'SET_TASK':
            return {
                task: action.payload
            }
        case 'ADD_JOB':
            return {
                task: state.task.jobs.push(action.payload)
            }
        default:
            return state
    }
}

export const TaskContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(taskReducer, { task: null })


    return (
        <TaskContext.Provider value={{ ...state, dispatch }}>

            {children}

        </TaskContext.Provider>
    )
}