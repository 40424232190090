import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

export const useSignup = () => {

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()


    const signup = async (email, password, firstname, lastname, username, confirmPassword) => {

        setIsLoading(true)
        setError(null)

        if (password !== confirmPassword) {
            setError("Passwords Do Not Match")
            setIsLoading(false)
            return
        }

        const response = await fetch(process.env.REACT_APP_BACKENDURL + '/api/user/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, firstname, lastname, username })
        })

        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }

        if (response.ok) {

           // var delayInMilliseconds = 30000; // Simulate Cold Start of Backend Server

            //setTimeout(function () {

                //save the user token to local storage
                localStorage.setItem('user', JSON.stringify(json))

                //update the auth context
                dispatch({ type: 'LOGIN', payload: json })


                setIsLoading(false)

           // }, delayInMilliseconds);
        }
    }

    return { signup, isLoading, error }
}