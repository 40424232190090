import { Typography, Box, Fade } from "@mui/material";
import { useState } from "react"
import { useLogin } from "../hooks/useLogin";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';


const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    //const [display, setDisplay] = useState(false)

    const { login, error, isLoading } = useLogin()


    const handleSubmit = async (e) => {
        e.preventDefault()

        await login(email.toLowerCase(), password)
    }

    return (
        <>
            <Box

                id="test"

                sx={{
                    marginTop: "10%",
                    marginLeft: { xs: '5%', sm: '15%', md: '25%', lg: '35%' },
                    marginRight: { xs: '5%', sm: '15%', md: '25%', lg: '35%' },
                    marginBottom: '10%'
                }}

            >
                <Typography variant="h4" sx={{
                    paddingTop: 3,
                    paddingBottom: 6
                }}> Login</Typography>
                <TextField id="outlined-basic-email" label="Email Address" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: 1,
                    marginBottom: 1

                }}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}

                    inputProps={{ style: { textTransform: "lowercase" } }}
                />
                <TextField id="outlined-basic-password" label="Password" variant="outlined" sx={{
                    marginLeft: { xs: 4, sm: 10 },
                    marginRight: { xs: 4, sm: 10 },
                    marginTop: 1,
                    marginBottom: 1
                }}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />

                <FormControlLabel
                    value="end"
                    control={<Checkbox onClick={handleClickShowPassword} />}
                    label="Show Password"
                    labelPlacement="end"
                    sx={{
                        marginLeft: { xs: 4, sm: 10 },
                        marginRight: { xs: 4, sm: 10 },
                        marginTop: 0,
                        marginBottom: 1
                    }}

                />

                <Typography sx={{ color: 'red' }}>{error}</Typography>

                <Box sx={{

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8,
                    marginTop: 2


                }}>
                    <Button variant="outlined" size="small" sx={{
                        borderRadius: 25,
                        border: 3,
                        ":hover": {
                            border: 3,
                        },
                        borderColor: "primary.dark",
                        color: "primary.light",
                        fontFamily: 'Allerta Stencil, sans-serif',
                    }}
                        disabled={true}
                    >Forgot??</Button>

                    <Box sx={{ m: 1, position: 'relative' }}>

                        <Button variant="contained" size="large" sx={{
                            borderRadius: 25,
                            backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                            fontFamily: 'Allerta Stencil, sans-serif',
                            marginLeft: { xs: 5, sm: 17 },
                        }}
                            disabled={isLoading}
                            onClick={handleSubmit}
                        >Login</Button>

                        {isLoading && (
                            <CircularProgress
                                size={28}
                                sx={{
                                    color: '#000000',
                                    position: 'absolute',
                                    top: { xs: '18%', sm: '18%' },
                                    left: { xs: '54%', sm: '74%' },
                                }}
                            />
                        )}

                        <Fade
                            in={isLoading}
                            style={{
                                transitionDelay: isLoading ? '10000ms' : '0ms'
                            }}

                            unmountOnExit
                        >


                            <Typography sx={{
                                position: 'absolute',
                                top: { xs: '45px', sm: '45px' },
                                left: { xs: '-71px', sm: '-55px' },
                                marginTop: '10px',
                                color: '#880808'
                            }}>
                                Waking up Server Please Wait . . .</Typography>

                        </Fade>

                    </Box>


                </Box>


            </Box >



        </>
    )
}

export default Login