import { Typography, Box } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const ResetPassword = () => {
    return (
        <>
            <Box

                id="test"

                sx={{
                    marginTop: "10%",
                    marginLeft: {  xs: '5%', sm:'15%', md: '25%', lg: '35%' },
                    marginRight: { xs: '5%', sm:'15%', md: '25%', lg: '35%' },
                    marginBottom: '10%'
                }}

            >
                <Typography variant="h4" sx={{
                    paddingTop: 3,
                    paddingBottom: 6
                }}> Reset Password</Typography>
                <TextField id="outlined-basic" label="User Name" variant="outlined" sx={{
                    marginLeft: {xs: 4, sm:10},
                    marginRight: {xs: 4, sm:10},
                    marginTop: 1,
                    marginBottom: 1

                }} />
                <TextField id="outlined-basic" label="Email Address" variant="outlined" type="password" sx={{
                    marginLeft: {xs: 4, sm:10},
                    marginRight: {xs: 4, sm:10},
                    marginTop: 1,
                    marginBottom: 1
                }} />

                

                <Box sx={{

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 8,
                    marginTop: 2


                }}>
                    <Button variant="outlined" size="small" sx={{
                        borderRadius: 25,
                        border: 3,
                        ":hover": {
                            border: 3,
                        },
                        borderColor: "primary.dark",
                        color: "primary.light",
                        fontFamily: 'Allerta Stencil, sans-serif',
                    }} href='/Login'>You Remember?</Button>

                    <Button variant="contained" size="large" sx={{
                        borderRadius: 25,
                        backgroundImage: 'linear-gradient(180deg, rgba(5, 0, 255, 1) 0%, rgba(0, 255, 56, 1) 100%)',
                        fontFamily: 'Allerta Stencil, sans-serif',
                        marginLeft: {xs: 5, sm: 17},
                    }} href='/'>Reset</Button>


                </Box>


            </Box>



        </>
    )
}

export default ResetPassword