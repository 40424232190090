import {Typography, Box } from "@mui/material";
import "../css/ExampleCard.css"

function ExampleCard({ title, description, image}) {
    return (
        <>
            <div className="center">
                <Box
                    component="img"
                    sx={{
                        height: { xs: 300, sm: 400, md: 500},
                        width: {xs: 300, sm: 400, md: 500},
                    }}
                    alt="Example Images"
                    src={image}
                />
                <div className="UseCase">
                    <Typography variant="h4" sx={{ paddingTop: 2 }}> {title}</Typography>

                </div>
                <div className="UseCase">
                    <Typography variant="h5" sx={{ padding: 2 }}> {description}</Typography>

                </div>

            </div>

        </>
    )
}

export default ExampleCard