import { Typography, Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Monkey from "../images/Monkey.png"
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="Home">
                <Box sx={{ textAlign: "center", marginTop: '30px' }}>
                    <Typography variant="h2" sx={{ fontSize: { xs: '40px', sm: '50px', md: '70px' }, color: "black" }}>ERROR</Typography>

                </Box>
            </div>
            <br></br>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>


                <Box
                    component='img'
                    sx={{
                        height: { xs: 300, sm: 400, md: 500 },
                        width: { xs: 300, sm: 400, md: 500 },
                    }}
                    alt="Error Image"
                    src={Monkey}

                ></Box>


                <Typography variant="h5" sx={{ fontSize: { xs: '35px', sm: '50px', md: '50px' }, textAlign: "center" }}> No Monkey Buisness</Typography>

            </Box>



            <Footer></Footer>

        </>
    )
}

export default Home;