import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                p: 6,

                marginTop: 10
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            About Us
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Keep Track Of
                            What You Do
                            When You Do It.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <Link color="inherit" href="https://www.diytasktracker.com/#/Dev">
                                Developer Updates Page
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Contact Us
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                            Email: Comming Soon
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Copyright
                        </Typography>
                        <Typography variant="body2" color="text.secondary" >
                            {"Copyright © "}
                            <Link color="inherit" href="https://www.diytasktracker.com/">
                                DIYTaskTracker
                            </Link>{" "}
                            {new Date().getFullYear()}
                            {"."}
                        </Typography>
                    </Grid>
                </Grid>

            </Container>
        </Box>
    );
}